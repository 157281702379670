import React from "react";

import "./Button.scss";

const Button = (props) => (
  <button
    className={`custom-button ${
      props.primary ? "primary" : props.black ? "black" : ""
    }`}
    {...props}
  >
    {props.children}
  </button>
);

export default Button;
