import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import { connect } from "react-redux";

import "./SearchDropdown.scss";

import { hideSearchDropdown } from "../../redux/search/search.actions";

const ProductResult = ({ product }) => (
  <div className="result-item">
    <div className="product-name">{product.Name}</div>
    <div className="in-collection">in {product.Collection}</div>
  </div>
);

const CollectionResult = ({ collection }) => (
  <div className="result-item">{collection.title}</div>
);

const SearchDropdown = ({
  collections,
  products,
  hideSearchDropdown,
  changeHandler,
  searchTerm,
}) => {
  const handleClick = () => {
    hideSearchDropdown();
  };
  const NoResults = () => {
    if (!collections.length && !products.length && searchTerm.length) {
      return <div className="no-results">No Results</div>;
    }
    return null;
  };

  return (
    <div className="search-dropdown">
      <div className="close-button" onClick={hideSearchDropdown}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="24"
          height="24"
        >
          <path fill="none" d="M0 0h24v24H0z" />
          <path d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
        </svg>
      </div>
      <form className="search-container">
        <input
          className="search-bar"
          type="text"
          placeholder="Search everything..."
          onChange={changeHandler}
        />
      </form>
      <div className="results-container">
        <div className="results-wrapper">
          {collections.length ? <h3>Collections</h3> : null}
          {collections.map((collection, idx) => (
            <Link
              onClick={handleClick}
              key={idx}
              to={`/shop/collection/${slugify(collection.title, {
                replacement: "-",
                lower: true,
                strict: true,
              })}`}
            >
              <CollectionResult collection={collection} />
            </Link>
          ))}
          {products.length ? (
            <h3 className="products-heading">Products</h3>
          ) : null}
          {products.map((product, idx) => (
            <Link
              onClick={handleClick}
              key={idx}
              to={`/product/${slugify(product.Name, {
                replacement: "-",
                lower: true,
                strict: true,
              })}`}
            >
              <ProductResult product={product} />
            </Link>
          ))}
          <NoResults />
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  hideSearchDropdown: () => dispatch(hideSearchDropdown()),
});

export default connect(null, mapDispatchToProps)(SearchDropdown);
