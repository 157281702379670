import React from "react";
import { Link, NavLink, useRouteMatch } from "react-router-dom";
import slugify from "slugify";

import "./ShopSidebar.scss";

import { ReactComponent as Logo } from "../../assets/logo.svg";

const ShopSidebar = () => {
  const match = useRouteMatch();

  const categoryLinks = [
    "Apparel",
    "Catamarans",
    "Kayaking",
    "Kiteboarding",
    "Paddleboarding",
    "Surfing",
    "Towables",
    "Wakeboarding",
    "Waterskiing",
    "Windsurfing",
  ];

  // const brandLinks = [
  //   "BICSport",
  //   "Cabrinha",
  //   "Connelly",
  //   "Duotone",
  //   "Firewire",
  //   "ION",
  //   "JP Australia",
  //   "North Kiteboarding",
  //   "NeilPryde",
  //   "Topcat",
  // ];

  // const links = match.path === "/shop" ? categoryLinks : brandLinks;
  const links = categoryLinks;

  return (
    <div className="shop-sidebar">
      <div className="sidebar-wrapper">
        <Link to="/">
          <Logo className="logo" />
        </Link>
        <h2 className="sidebar-title">{match.path.replace("/", "")}</h2>
        {links.map((link, index) => (
          <NavLink
            key={index}
            to={`${match.path}/${slugify(link, { lower: true, strict: true })}`}
            activeClassName="active-sidebar-link"
            className="sidebar-link"
          >
            {link}
          </NavLink>
        ))}
        <div className="social-links">
          <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 2C6.477 2 2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.879V14.89h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.989C18.343 21.129 22 16.99 22 12c0-5.523-4.477-10-10-10z" />
            </svg>
          </a>
          <a href="#">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M12 2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2zm0 5a5 5 0 1 0 0 10 5 5 0 0 0 0-10zm6.5-.25a1.25 1.25 0 0 0-2.5 0 1.25 1.25 0 0 0 2.5 0zM12 9a3 3 0 1 1 0 6 3 3 0 0 1 0-6z" />
            </svg>
          </a>
          <a href="mailto:info@aquacube.store">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
              <path d="M3 3h18a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm9.06 8.683L5.648 6.238 4.353 7.762l7.72 6.555 7.581-6.56-1.308-1.513-6.285 5.439z" />
            </svg>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ShopSidebar;
