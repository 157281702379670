import React from "react";

import Footer from "../Footer/Footer";
import CategoryItem from "../CategoryItem/CategoryItem";

import "./CategoriesOverview.scss";

const categories = [
  {
    title: "Apparel",
    imageUrl: "https://i.ibb.co/YTdh9kV/vests.jpg",
  },
  {
    title: "Catamarans",
    imageUrl: "https://i.ibb.co/gM7kC3r/catamaran.jpg",
  },
  {
    title: "Kayaking",
    imageUrl: "https://i.ibb.co/hdkPt5d/kayaks.jpg",
  },
  {
    title: "Kiteboarding",
    imageUrl: "https://i.ibb.co/fMgQcmg/kites.jpg",
  },
  {
    title: "Paddleboarding",
    imageUrl: "https://i.ibb.co/dQGTnTr/sup-boards.jpg",
  },
  {
    title: "Surfing",
    imageUrl: "https://i.ibb.co/hV0VqFL/surfboards.jpg",
  },
  {
    title: "Towables",
    imageUrl: "https://i.ibb.co/RBV2kQj/towables.jpg",
  },
  {
    title: "Wakeboarding",
    imageUrl: "https://i.ibb.co/7Gxd1nH/wakeboards.jpg",
  },
  {
    title: "Waterskiing",
    imageUrl: "https://i.ibb.co/WK6qbVV/skis.jpg",
  },
  {
    title: "Windsurfing",
    imageUrl: "https://i.ibb.co/FbqkTSv/windsurf-sails.jpg",
  },
];

const CategoriesOverview = () => {
  console.log(categories);

  return (
    <div className="categories-overview">
      <h1>Categories</h1>
      <div className="category-items">
        {categories.map((category, idx) => {
          return <CategoryItem item={category} key={idx} />;
        })}
      </div>
      <Footer />
    </div>
  );
};

export default CategoriesOverview;
