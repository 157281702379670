import React from "react";

import "./Loader.scss";

const Loader = (WrappedComponent) => ({ isLoading, ...otherProps }) => {
  return isLoading ? (
    <div className="loader-overlay">
      <div className="waves-container">
        <svg
          className="waves"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 320 162.514"
        >
          <g transform="translate(-170 -116.49)">
            <path
              d="M353,369.5c12.791,0,22.249-17.5,40-17.5s21.793,17.5,40,17.5S455.613,352,473,352s21.373,17.5,40,17.5,21.527-18.016,40-17.5,21.163,17.5,40,17.5S615.984,352,633,352s28.773,17.5,40,17.5"
              transform="translate(-183 -229)"
              fill="none"
              stroke="#000"
              strokeWidth="13"
            />
            <path
              d="M353,369.5c12.791,0,22.249-17.5,40-17.5s21.793,17.5,40,17.5S455.613,352,473,352s21.373,17.5,40,17.5,21.527-18.016,40-17.5,21.163,17.5,40,17.5S615.984,352,633,352s28.773,17.5,40,17.5"
              transform="translate(-183 -141)"
              fill="none"
              stroke="#000"
              strokeWidth="13"
            />
            <path
              d="M353,369.5c12.791,0,22.249-17.5,40-17.5s21.793,17.5,40,17.5S455.613,352,473,352s21.373,17.5,40,17.5,21.527-18.016,40-17.5,21.163,17.5,40,17.5S615.984,352,633,352s28.773,17.5,40,17.5"
              transform="translate(-183 -185)"
              fill="none"
              stroke="#000"
              strokeWidth="13"
            />
            <path
              d="M353,369.5c12.791,0,22.249-17.5,40-17.5s21.793,17.5,40,17.5S455.613,352,473,352s21.373,17.5,40,17.5,21.527-18.016,40-17.5,21.163,17.5,40,17.5S615.984,352,633,352s28.773,17.5,40,17.5"
              transform="translate(-183 -97)"
              fill="none"
              stroke="#000"
              strokeWidth="13"
            />
          </g>
        </svg>
      </div>
    </div>
  ) : (
    <WrappedComponent {...otherProps} />
  );
};

export default Loader;
