import React from "react";

import "./CartItem.scss";

const CartItem = ({ item: { Image, Name, quantity, size } }) => (
  <div className="cart-item">
    <div className="image" style={{ backgroundImage: `url(${Image})` }}></div>
    <div className="item-details">
      <span className="name">{Name}</span>
      {size ? <span className="size">Size: {size}</span> : null}
      <span className="quantity">Quantity: {quantity}</span>
    </div>
  </div>
);

export default CartItem;
