import React from "react";
import { Link } from "react-router-dom";
import slugify from "slugify";
import Img from "react-cool-img";

import "./CollectionItem.scss";

const CollectionItem = ({ item }) => {
  const { Name, Image } = item;
  return (
    <Link
      to={`/product/${slugify(Name, {
        replacement: "-",
        lower: true,
        strict: true,
      })}`}
    >
      <div className="collection-item">
        <div className="image-container">
          <Img src={Image} alt={Name} />
        </div>
        <div className="collection-footer">{Name}</div>
      </div>
    </Link>
  );
};

export default CollectionItem;
