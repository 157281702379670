import React from "react";
import { useHistory } from "react-router-dom";

import "./HomePage.scss";

import Button from "../../components/Button/Button";
import CategoriesOverviewContainer from "../../components/CategoriesOverview/CategoriesOverviewContainer";

const HomePage = () => {
  const history = useHistory();
  return (
    <div className="homepage">
      <div className="hero-area">
        <h1>Aqua Cube</h1>
        <p>Your one-stop shop for watersports equipment in maldives</p>

        <Button primary="true" onClick={() => history.push("/shop")}>
          Shop Now
        </Button>
      </div>
      <div className="homepage-contents">
        <div className="categories-container">
          <CategoriesOverviewContainer />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
