import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { compose } from "redux";

import { selectIsCollectionsFetching } from "../../redux/shop/shop.selectors";
import Loader from "../Loader/Loader";
import CategoriesOverview from "./CategoriesOverview";

const mapStateToProps = createStructuredSelector({
  isLoading: selectIsCollectionsFetching,
});

const CategoriesOverviewContainer = compose(
  connect(mapStateToProps),
  Loader
)(CategoriesOverview);

export default CategoriesOverviewContainer;
