export const addItemToCart = (cartItems, itemToAdd, quantityToAdd, size) => {
  const existingCartItem = cartItems.find(
    (cartItem) => cartItem.Name === itemToAdd.Name && cartItem.size === size
  );

  if (existingCartItem) {
    return cartItems.map((cartItem) =>
      cartItem.Name === itemToAdd.Name && cartItem.size === size
        ? { ...cartItem, quantity: cartItem.quantity + quantityToAdd }
        : cartItem
    );
  }

  return [...cartItems, { ...itemToAdd, quantity: quantityToAdd, size: size }];
};

export const removeItemFromCart = (cartItems, cartItemToRemove) => {
  const existingCartItem = cartItems.find(
    (cartItem) =>
      cartItem.Name === cartItemToRemove.Name &&
      cartItem.size === cartItemToRemove.size
  );

  if (existingCartItem.quantity === 1) {
    return cartItems.filter(
      (cartItem) =>
        !(
          cartItem.Name === cartItemToRemove.Name &&
          cartItem.size === cartItemToRemove.size
        )
    );
  }

  return cartItems.map((cartItem) =>
    cartItem.Name === cartItemToRemove.Name &&
    cartItem.size === cartItemToRemove.size
      ? { ...cartItem, quantity: cartItem.quantity - 1 }
      : cartItem
  );
};
