import React from "react";
import { Route } from "react-router-dom";

import ShopSidebar from "../../components/ShopSidebar/ShopSidebar";
import CategoriesOverviewContainer from "../../components/CategoriesOverview/CategoriesOverviewContainer";
import CategoryPage from "../CategoryPage/CategoryPage";
import CollectionPageContainer from "../CollectionPage/CollectionPageContainer";

const ShopPage = ({ match }) => {
  return (
    <div className="shop-page">
      <ShopSidebar />
      <Route
        path={`${match.path}/:category/:collection`}
        component={CollectionPageContainer}
      />
      <Route exact path={`${match.path}/:category`} component={CategoryPage} />

      <Route
        exact
        path={`${match.path}`}
        component={CategoriesOverviewContainer}
      />
    </div>
  );
};

export default ShopPage;
