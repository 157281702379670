import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { unslugify } from "../../utils/utils";

import CollectionItem from "../../components/CollectionItem/CollectionItem";
import Footer from "../../components/Footer/Footer";

import { selectCollection } from "../../redux/shop/shop.selectors";
import { selectCollectionMeta } from "../../redux/directory/directory.selectors";
import { getUniqueFilters } from "../../utils/utils";
import FilterTag from "../../components/FilterTag/FilterTag";

import "./CollectionPage.scss";

const CollectionPage = ({ collection, match, collectionMetaData }) => {
  const [products, setProducts] = useState(collection);
  const [filterState, setFilterState] = useState({});

  let filterCategories = [];
  let filters = {};
  if (collectionMetaData.filters) {
    filterCategories = collectionMetaData.filters;
    filters = getUniqueFilters(collection, filterCategories);
  }

  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setProducts(getFilteredProducts());
    // eslint-disable-next-line
  }, [filterState]);

  useEffect(() => {
    setProducts(collection);
    // eslint-disable-next-line
  }, [match.params.collection]);

  const getFilteredProducts = () => {
    return collection.filter((product) => {
      let isMatch = true;
      Object.keys(filterState).forEach((filter) => {
        if (product[filter] !== filterState[filter]) {
          isMatch = false;
        }
      });
      return isMatch;
    });
  };

  const addFilter = (key, value) => {
    setFilterState((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const removeFilter = (keyToRemove) => {
    setFilterState((prevFilters) => {
      return Object.keys(prevFilters).reduce((newFilters, key) => {
        if (key !== keyToRemove) {
          newFilters[key] = prevFilters[key];
        }
        return newFilters;
      }, {});
    });
  };

  const NoProductsMessage = () => {
    if (!products.length) {
      return (
        <div className="no-products-message">
          <h2>No products found</h2>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="collection-page">
      <h2 className="title">{unslugify(match.params.collection)}</h2>
      <div className="filter-area">
        {Object.entries(filters).map(([key, filterValues], idx) => (
          <div className="filter-group" key={idx}>
            <span className="filter-label">{key}:</span>
            <div className="filter-button-group">
              <FilterTag
                onClick={() => removeFilter(key)}
                isActive={filterState[key] === undefined}
              >
                All
              </FilterTag>
              {filterValues.map((value, idx) => (
                <FilterTag
                  key={idx}
                  onClick={() => addFilter(key, value)}
                  isActive={filterState[key] === value}
                >
                  {value}
                </FilterTag>
              ))}
            </div>
          </div>
        ))}
      </div>
      <div className="items">
        {products.map((item, idx) => (
          <CollectionItem key={idx} item={item} />
        ))}
      </div>
      <NoProductsMessage />
      <Footer />
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  collection: selectCollection(ownProps.match.params.collection)(state),
  collectionMetaData: selectCollectionMeta(
    unslugify(ownProps.match.params.collection)
  )(state),
});

export default connect(mapStateToProps)(CollectionPage);
