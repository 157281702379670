import React, { useState } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { selectCartItems } from "../../redux/cart/cart.selectors";

import "./CheckoutPage.scss";
import CheckoutItem from "../../components/CheckoutItem/CheckoutItem";
import Button from "../../components/Button/Button";
import { Link } from "react-router-dom";

const CheckoutContents = ({ cartItems }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [statusMessage, setStatusMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    const products = cartItems.map((item) => {
      return { name: item.Name, size: item.size };
    });

    const productsString = products.reduce((acc, product) => {
      return (acc += `Name: ${product.name} \n Size: ${product.size}\n\n`);
    }, "");

    const data = {
      name,
      email,
      phone,
      message,
      productsString,
    };

    fetch(
      "https://ot5aij3n73.execute-api.us-east-1.amazonaws.com/dev/static-site-mailer",
      {
        method: "POST",
        body: JSON.stringify(data),
      }
    ).then((response) => {
      if (response.status === 200) {
        setStatusMessage("Thanks for the message. We'll be in touch shortly.");
      } else {
        setStatusMessage(
          "Something went wrong. Please try again later or give us a call."
        );
      }
    });
  };
  return (
    <div className="checkout-contents">
      <div className="checkout-items-container">
        <h2>Products</h2>
        {cartItems.map((cartItem, idx) => (
          <CheckoutItem key={idx} cartItem={cartItem} />
        ))}
      </div>
      <div className="form-container">
        <h2>Your contact details</h2>
        <form onSubmit={handleSubmit} className="checkout-form">
          <label htmlFor="name">Name</label>
          <input
            type="text"
            id="name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
          <label htmlFor="email">Email</label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <label htmlFor="phone">Phone</label>
          <input
            type="text"
            id="phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            required
          />
          <label htmlFor="message">Message (Optional)</label>
          <textarea
            id="message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <Button black="true" type="submit">
            Request quote
          </Button>
        </form>
        <p>{statusMessage}</p>
      </div>
    </div>
  );
};

const EmptyMessage = () => (
  <div className="empty-message">
    <h2>There doesn’t seem to be anything here</h2>
    <Link to="/shop">Go back to shop and add items.</Link>
  </div>
);

const CheckoutPage = ({ cartItems }) => (
  <div className="checkout-page">
    <h1>Enquiry</h1>
    {cartItems.length ? (
      <CheckoutContents cartItems={cartItems} />
    ) : (
      <EmptyMessage />
    )}
  </div>
);

const mapStateToProps = createStructuredSelector({
  cartItems: selectCartItems,
});

export default connect(mapStateToProps)(CheckoutPage);
