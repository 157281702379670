import React from "react";
import { connect } from "react-redux";

import {
  clearItemFromCart,
  addItem,
  removeItem,
} from "../../redux/cart/cart.actions";

import "./CheckoutItem.scss";

const CheckoutItem = ({ cartItem, clearItem, addItem, removeItem }) => {
  const { Name, Image, quantity, size } = cartItem;
  return (
    <div className="checkout-item">
      <div className="image-container">
        <img src={Image} alt={Name} />
      </div>
      <div className="item-details">
        <span className="name">{Name}</span>
        {size ? <div className="size">Size: {size}</div> : null}
        <span className="quantity">
          <div
            className="quantity-modifier"
            onClick={() => removeItem(cartItem)}
          >
            &#45;
          </div>

          <span className="value">{quantity}</span>
          <div
            className="quantity-modifier"
            onClick={() => addItem(cartItem, 1, size)}
          >
            &#43;
          </div>
        </span>
      </div>

      <div className="remove-button" onClick={() => clearItem(cartItem)}>
        &#10005;
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  clearItem: (item) => dispatch(clearItemFromCart(item)),
  addItem: (item, quantity, size) => dispatch(addItem(item, quantity, size)),
  removeItem: (item) => dispatch(removeItem(item)),
});

export default connect(null, mapDispatchToProps)(CheckoutItem);
