import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import Img from "react-cool-img";

import "./ProductPage.scss";

import Button from "../../components/Button/Button";
import { addItem } from "../../redux/cart/cart.actions";

import { selectProduct } from "../../redux/shop/shop.selectors";

const ProductPage = ({ product, addItem }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    if (product.Sizes) {
      setSize(document.getElementById("size").value || null);
    }
    // eslint-disable-next-line
  }, []);

  const [quantity, setQuantity] = useState(1);

  const [size, setSize] = useState(null);

  if (product) {
    const { Name, Image, Description, Sizes } = product;
    const sizes = Sizes ? Sizes.split(", ") : null;

    return (
      <div className="product-page">
        <div className="details-container">
          <h1>{Name}</h1>
          <p className="description">{Description}</p>

          {sizes ? (
            <>
              <label htmlFor="size">Size:</label>
              <select id="size" onChange={(e) => setSize(e.target.value)}>
                {sizes.map((size, idx) => (
                  <option key={idx} value={`${size}`}>
                    {size}
                  </option>
                ))}
              </select>
            </>
          ) : null}
          <input
            type="number"
            value={quantity}
            onChange={(e) => setQuantity(e.target.value)}
            min="1"
            name="quantity"
            className="quantity"
          />
          <Button
            black="true"
            onClick={() => addItem(product, parseInt(quantity), size)}
          >
            Add to cart
          </Button>
          <div className="get-help">
            {" "}
            Get help buying. Send message or call 999-6856.
          </div>
        </div>
        <div className="image-container">
          <Img src={Image} alt={Name} />
        </div>
      </div>
    );
  } else {
    return <h3>This product does not exist</h3>;
  }
};

const mapStateToProps = (state, ownProps) => ({
  product: selectProduct(ownProps.match.params.productSlug)(state),
});

const mapDispatchToProps = (dispatch) => ({
  addItem: (product, quantity, size) =>
    dispatch(addItem(product, quantity, size)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
