import React from "react";

import MenuItem from "../MenuItem/MenuItem";
import Footer from "../Footer/Footer";

import "./CategoryMenu.scss";

const CategoryMenu = ({ collections }) => (
  <div className="category-menu">
    {collections.map((collection, idx) => (
      <MenuItem key={idx} collection={collection} />
    ))}
    <Footer />
  </div>
);

export default CategoryMenu;
