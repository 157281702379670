import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";
import { Link, NavLink } from "react-router-dom";

import "./Navbar.scss";

import { ReactComponent as Logo } from "../../assets/logo.svg";
import CartIcon from "../CartIcon/CartIcon";
import CartDropdown from "../CartDropdown/CartDropdown";
import SearchDropdown from "../SearchDropdown/SearchDropdown";
import Menu from "../Menu/Menu";

import { selectCartHidden } from "../../redux/cart/cart.selectors";
import { selectSearchHidden } from "../../redux/search/search.selectors";
import { selectProducts } from "../../redux/shop/shop.selectors";
import { selectCollections } from "../../redux/directory/directory.selectors";

import {
  showSearchDropdown,
  hideSearchDropdown,
} from "../../redux/search/search.actions";

const Header = ({
  cartHidden,
  searchHidden,
  collections,
  products,
  showSearchDropdown,
  hideSearchDropdown,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchedCollections, setSearchedCollections] = useState([]);
  const [searchedProducts, setSearchedProducts] = useState([]);
  const [menuHidden, setMenuHidden] = useState(true);

  useEffect(() => {
    applySearch();
    // eslint-disable-next-line
  }, [searchTerm]);

  const getFilteredCollections = () => {
    return collections
      .filter((collection) => {
        return collection.title
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      })
      .slice(0, 4);
  };

  const getFilteredProducts = () => {
    return products
      .filter((collection) => {
        return collection.Name.toLowerCase().includes(searchTerm.toLowerCase());
      })
      .slice(0, 4);
  };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const applySearch = () => {
    if (searchTerm.length) {
      setSearchedCollections(getFilteredCollections());
      setSearchedProducts(getFilteredProducts());
    } else {
      setSearchedCollections([]);
      setSearchedProducts([]);
    }
  };

  const menuCloseFunction = () => {
    setMenuHidden(true);
  };
  return (
    <div className="navbar">
      <Link className="logo-container" to="/">
        <Logo className="logo" />
      </Link>

      {searchHidden ? null : (
        <SearchDropdown
          changeHandler={handleSearchChange}
          collections={searchedCollections}
          products={searchedProducts}
          hideDropdown={hideSearchDropdown}
          searchTerm={searchTerm}
        />
      )}
      <div className="links-container">
        <div className="search-icon" onClick={showSearchDropdown}>
          <svg
            className="search"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="2rem"
            height="2rem"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M11 2c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 16c3.867 0 7-3.133 7-7 0-3.868-3.133-7-7-7-3.868 0-7 3.132-7 7 0 3.867 3.132 7 7 7zm8.485.071l2.829 2.828-1.415 1.415-2.828-2.829 1.414-1.414z" />
          </svg>
        </div>
        <NavLink activeClassName="active-link" className="nav-link" to="/shop">
          Shop
        </NavLink>
        <NavLink
          activeClassName="active-link"
          className="nav-link"
          to="/contact"
        >
          Contact
        </NavLink>
        <CartIcon />
        <div className="menu-icon" onClick={() => setMenuHidden(false)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="24"
            height="24"
          >
            <path fill="none" d="M0 0h24v24H0z" />
            <path d="M3 4h18v2H3V4zm6 7h12v2H9v-2zm-6 7h18v2H3v-2z" />
          </svg>
        </div>
      </div>
      {cartHidden ? null : <CartDropdown />}
      {menuHidden ? null : <Menu closeFunction={menuCloseFunction} />}
    </div>
  );
};

const mapStateToProps = createStructuredSelector({
  cartHidden: selectCartHidden,
  collections: selectCollections,
  products: selectProducts,
  searchHidden: selectSearchHidden,
});

const mapDispatchToProps = (dispatch) => ({
  showSearchDropdown: () => dispatch(showSearchDropdown()),
  hideSearchDropdown: () => dispatch(hideSearchDropdown()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
