import ShopActionTypes from "./shop.types";

export const fetchProductsStart = () => ({
  type: ShopActionTypes.FETCH_PRODUCTS_START,
});

export const fetchProductsSuccess = (collectionsMap) => ({
  type: ShopActionTypes.FETCH_PRODUCTS_SUCCESS,
  payload: collectionsMap,
});

export const fetchProductsFailure = (errorMessage) => ({
  type: ShopActionTypes.FETCH_PRODUCTS_FAILURE,
  payload: errorMessage,
});

// export const fetchProductsStartAsync = () => {
//   return (dispatch) => {
//     const collectionRef = firestore.collection("collections");
//     dispatch(fetchProductsStart());

//     collectionRef
//       .get()
//       .then((snapshot) => {
//         const collectionsMap = convertCollectionsSnapshotToMap(snapshot);
//         dispatch(fetchProductsSuccess(collectionsMap));
//       })
//       .catch((error) => dispatch(fetchProductsFailure(error.errorMessage)));
//   };
// };
