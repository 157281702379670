import { createSelector } from "reselect";
// import slugify from "slugify";
import { unslugify } from "../../utils/utils";

const selectShop = (state) => state.shop;

export const selectProducts = createSelector(
  [selectShop],
  (shop) => shop.products
);

export const selectCollection = (collectionUrlParam) =>
  createSelector([selectProducts], (products) => {
    const collectionName = unslugify(collectionUrlParam).toLowerCase();
    return products
      ? products.filter(
          (product) => product.Collection.toLowerCase() === collectionName
        )
      : [];
  });

export const selectProduct = (productSlug) =>
  createSelector([selectProducts], (products) =>
    products ? products.find((product) => product.Slug === productSlug) : null
  );

export const selectIsCollectionsFetching = createSelector(
  [selectShop],
  (shop) => shop.isFetching
);

export const selectIsProductsLoaded = createSelector(
  [selectShop],
  (shop) => !!shop.products
);
