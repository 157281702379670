import React from "react";

import "./ContactPage.scss";

import Footer from "../../components/Footer/Footer";

const ContactPage = () => {
  return (
    <div className="contact-page">
      <h1>Contact us</h1>
      <p>Email</p>
      <h2>info@aquacube.store</h2>
      <p>Phone</p>
      <h2>+960 999 6856</h2>
      <p>Address</p>
      <h2>Ma. Petrel, 4th Floor, Iskandhar Magu, Male', Rep. of Maldives</h2>
      <Footer />
    </div>
  );
};

export default ContactPage;
