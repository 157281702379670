import React from "react";
import { withRouter } from "react-router-dom";
import slugify from "slugify";
import Img from "react-cool-img";

import "./MenuItem.scss";

const MenuItem = ({ collection, history, match }) => {
  const { title, imageUrl } = collection;
  return (
    <div
      className="menu-item"
      onClick={() =>
        history.push(
          `${match.url}/${slugify(title, {
            replacement: "-",
            lower: true,
            strict: "true",
          })}`
        )
      }
    >
      <div className="image-container">
        {" "}
        <Img src={imageUrl} alt={title} />
      </div>
      <div className="content">
        <h1 className="title">{title}</h1>
      </div>
    </div>
  );
};

export default withRouter(MenuItem);
