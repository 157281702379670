import React from "react";
import Img from "react-cool-img";
import { useHistory } from "react-router-dom";
import slugify from "slugify";

import "./CategoryItem.scss";

const CategoryItem = ({ item }) => {
  const history = useHistory();

  const { title, imageUrl } = item;
  return (
    <div
      className="category-item"
      onClick={() =>
        history.push(
          `/shop/${slugify(title, {
            replacement: "-",
            lower: true,
            strict: "true",
          })}`
        )
      }
    >
      <div className="image-container">
        <Img src={imageUrl} alt={title} />
      </div>
      <div className="content">
        <h1 className="title">{title}</h1>
      </div>
    </div>
  );
};

export default CategoryItem;
