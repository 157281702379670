import { GoogleSpreadsheet } from "google-spreadsheet";
import slugify from "slugify";

const doc = new GoogleSpreadsheet(
  process.env.REACT_APP_GOOGLE_SHEETS_SPREADSHEET_ID
);

export const getProducts = async () => {
  try {
    await doc.useApiKey(process.env.REACT_APP_GOOGLE_SHEETS_API_KEY);

    await doc.loadInfo();
    const sheets = await doc.sheetsByIndex;

    const items = await Promise.all(
      sheets.map(async (sheet) => {
        const rows = await sheet.getRows();
        const newRows = rows.map((item) => {
          const headerValues = item._sheet.headerValues;
          const newItem = {};

          headerValues.forEach((value) => {
            newItem[value] = item[value];
          });

          newItem.Slug = slugify(item.Name, { lower: true, strict: true });

          return newItem;
        });

        return [...newRows];
      })
    );
    return await items.flat();
  } catch (error) {
    console.error(error.message);
    throw error;
  }
};
