import { connect } from "react-redux";
import { compose } from "redux";
import { createStructuredSelector } from "reselect";

import { selectIsProductsLoaded } from "../../redux/shop/shop.selectors";
import Loader from "../../components/Loader/Loader";
import CollectionPage from "./CollectionPage";

const mapStateToProps = createStructuredSelector({
  isLoading: (state) => !selectIsProductsLoaded(state),
});

const CollectionPageContainer = compose(
  connect(mapStateToProps),
  Loader
)(CollectionPage);

export default CollectionPageContainer;
