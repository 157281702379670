import { createSelector } from "reselect";

const selectDirectory = (state) => state.directory;

export const selectCategories = createSelector(
  [selectDirectory],
  (directory) => directory.categories
);

export const selectCategory = (category) =>
  createSelector([selectCategories], (categories) => categories[category]);

export const selectCollections = createSelector(
  [selectCategories],
  (categories) => Object.values(categories).flat()
);

export const selectCollectionMeta = (collectionName) =>
  createSelector([selectCollections], (collections) =>
    collections.find((collection) => collection.title === collectionName)
  );
