import { takeLatest, call, put, all } from "redux-saga/effects";

import { getProducts } from "../../sheets/sheets.utils";

import { fetchProductsSuccess, fetchProductsFailure } from "./shop.actions";

import ShopActionTypes from "./shop.types";

export function* fetchProductsAsync() {
  try {
    const products = yield getProducts();
    yield put(fetchProductsSuccess(products));
  } catch (error) {
    yield put(fetchProductsFailure(error.message));
  }
}

export function* fetchProductsStart() {
  yield takeLatest(ShopActionTypes.FETCH_PRODUCTS_START, fetchProductsAsync);
}

export function* shopSagas() {
  yield all([call(fetchProductsStart)]);
}
