import React from "react";

import "./FilterTag.scss";

const FilterTag = (props) => {
  return (
    <button
      className={`filter-tag ${props.isActive ? "active" : ""}`}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
};

export default FilterTag;
