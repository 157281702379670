import React, { useEffect } from "react";
import { connect } from "react-redux";

import "./CategoryPage.scss";

import CategoryMenu from "../../components/CategoryMenu/CategoryMenu";

import { selectCategory } from "../../redux/directory/directory.selectors";

const CategoryPage = ({ match, collections }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);
  return (
    <div className="category-page">
      {collections ? (
        <>
          <h1>{match.params.category}</h1>
          <CategoryMenu collections={collections} />
        </>
      ) : (
        <h2>This page does not exist</h2>
      )}
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  collections: selectCategory(ownProps.match.params.category)(state),
});

export default connect(mapStateToProps)(CategoryPage);
