import React, { useEffect } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";

import "./App.scss";

import HomePage from "./pages/HomePage/HomePage";
import ShopPage from "./pages/ShopPage/ShopPage";
import CheckoutPage from "./pages/CheckoutPage/CheckoutPage";
import ContactPage from "./pages/ContactPage/ContactPage";
import ProductPageContainer from "./pages/ProductPage/ProductPageContainer";

import Navbar from "./components/Navbar/Navbar";

import { fetchProductsStart } from "./redux/shop/shop.actions";

const App = ({ fetchProductsStart, currentUser }) => {
  useEffect(() => {
    fetchProductsStart();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <div>
      <Navbar />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/shop" component={ShopPage} />
        <Route path="/contact" component={ContactPage} />
        <Route exact path="/checkout" component={CheckoutPage} />
        <Route path="/product/:productSlug" component={ProductPageContainer} />
      </Switch>
    </div>
  );
};

// const mapStateToProps = createStructuredSelector({

// });

const mapDispatchToProps = (dispatch) => ({
  fetchProductsStart: () => dispatch(fetchProductsStart()),
});

export default connect(null, mapDispatchToProps)(App);
