export function unslugify(slug) {
  // eslint-disable-next-line
  const result = slug.replace(/\-/g, " ");
  return result.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export const getUniqueFilters = (productsArray, filterCategories) => {
  const filters = {};

  filterCategories.forEach((filterCategory) => {
    const newArray = [];
    productsArray.forEach((product) => {
      if (!newArray.includes(product[filterCategory])) {
        newArray.push(product[filterCategory]);
      }
    });
    filters[filterCategory] = newArray;
  });

  return filters;
};
