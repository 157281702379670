const INITIAL_STATE = {
  categories: {
    apparel: [
      {
        title: "Vests",
        filters: ["Material", "Type"],
        imageUrl: "https://i.ibb.co/YTdh9kV/vests.jpg",
      },
      {
        title: "Footwear",
        filters: ["Type", "Brand"],
        imageUrl:
          "https://www.ion-products.com/fileadmin/user_upload/ion_water_footwear.jpg",
      },
      {
        title: "Hardcaps",
        imageUrl:
          "https://www.ion-products.com/fileadmin/user_upload/ion_water_kiteprotec.jpg",
      },
    ],
    catamarans: [
      {
        title: "Catamarans",
        imageUrl: "https://i.ibb.co/gM7kC3r/catamaran.jpg",
      },
    ],
    kayaking: [
      {
        title: "Kayaks",
        filters: ["Made", "Kayak Range", "Capacity"],
        imageUrl: "https://i.ibb.co/hdkPt5d/kayaks.jpg",
      },
      {
        title: "Kayak Paddles",
        imageUrl: "https://i.ibb.co/RcgcFP6/kayak-paddles.jpg",
      },
    ],
    kiteboarding: [
      {
        title: "Kites",
        filters: ["Brand"],
        imageUrl: "https://i.ibb.co/fMgQcmg/kites.jpg",
      },
      {
        title: "Kiteboards",
        filters: ["Type", "Brand"],
        imageUrl: "https://i.ibb.co/t31hFjh/kiteboards.jpg",
      },
      {
        title: "Kite Bars",
        filters: ["Brand"],
        imageUrl: "https://i.ibb.co/L9YHyrZ/kite-bars.jpg",
      },
      {
        title: "Kite Harnesses",
        filters: ["Filter"],
        imageUrl: "https://i.ibb.co/tQ5zf8s/kite-harnesses.jpg",
      },
      {
        title: "Footstraps And Bindings",
        imageUrl: "https://i.ibb.co/372jJc0/footstraps.jpg",
      },
    ],
    paddleboarding: [
      {
        title: "Sup Boards",
        filters: ["Construction", "Activity"],
        imageUrl: "https://i.ibb.co/dQGTnTr/sup-boards.jpg",
      },
      {
        title: "Sup Paddles",
        imageUrl: "https://i.ibb.co/3WsjT0B/sup-paddles.jpg",
      },
      {
        title: "Sup Accessories",
        imageUrl: "https://i.ibb.co/jJNmd7D/sup-accessories.jpg",
      },
    ],
    surfing: [
      {
        title: "Surfboards",
        filters: ["Brand"],
        imageUrl: "https://i.ibb.co/hV0VqFL/surfboards.jpg",
      },
      {
        title: "Surfboard Accessories",
        imageUrl: "https://i.ibb.co/ZcSvL83/surf-accessories.jpg",
      },
    ],
    towables: [
      {
        title: "Towables",
        filters: ["Capacity"],
        imageUrl: "https://i.ibb.co/RBV2kQj/towables.jpg",
      },
      {
        title: "Tube Ropes",
        imageUrl: "https://i.ibb.co/Qmhm7b4/tube-ropes.jpg",
      },
    ],
    wakeboarding: [
      {
        title: "Wakeboards",
        filters: ["Filter"],
        imageUrl: "https://i.ibb.co/7Gxd1nH/wakeboards.jpg",
      },
      {
        title: "Wake Ropes And Handles",
        imageUrl: "https://i.ibb.co/XkHsxM0/wake-ropes.jpg",
      },
    ],
    waterskiing: [
      {
        title: "Skis",
        filters: ["Type"],
        imageUrl: "https://i.ibb.co/WK6qbVV/skis.jpg",
      },
      {
        title: "Ski Ropes And Handles",
        imageUrl: "https://i.ibb.co/D7CRm50/ski-ropes.jpg",
      },
    ],
    windsurfing: [
      {
        title: "Windsurf Boards",
        imageUrl: "https://i.ibb.co/0qJ0wyG/windsurf-boards.jpg",
      },
      {
        title: "Sails",
        filters: ["Brand"],
        imageUrl: "https://i.ibb.co/FbqkTSv/windsurf-sails.jpg",
      },
      {
        title: "Windsurf Fins",
        imageUrl: "https://i.ibb.co/ySDtLWj/windsurf-fins.jpg",
      },
      {
        title: "Windsurf Harnesses",
        filters: ["Filter"],
        imageUrl: "https://i.ibb.co/QDVbNXV/windsurf-harness.jpg",
      },
    ],
  },
};

const directoryReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default directoryReducer;
